import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "auth"
    }}>{`Auth`}</h1>
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useAuth"
      }}>{`useAuth`}</a>{` together with Auth0 to add authentication to your app.`}</p>
    <p>{`Swizec walks you through the basic setup and you add your config values.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      